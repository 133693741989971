<script>
  import { onMount } from 'svelte'
  import ChromeSvg from '../../assets/icons/chrome-svg.svelte'

  const detectSafari = () => {
    let userAgentString = navigator.userAgent.toLowerCase()

    let safariAgent = userAgentString.includes('safari') && !userAgentString.includes('chrome')

    return safariAgent
  }

  let isSafari = false

  onMount(() => {
    isSafari = detectSafari()
  })
</script>

{#if isSafari}
  <div class="fixed bottom-0 left-0 right-0 bg-[var(--orange)] text-white py-4 px-3 text-center z-10">
    <p class="text-base md:text-lg lg:text-xl inline-flex items-center justify-center flex-wrap">
      Some features might not work correctly in&nbsp;<b>Safari</b>. For a better experience, please use:&nbsp;<a
        href="https://www.google.com/chrome/"
        target="_blank"
        rel="noopener noreferrer"
        class="hover:underline hover:text-blue-700"
        >google Chrome
      </a>
      <a
        href="https://www.google.com/chrome/"
        target="_blank"
        rel="noopener noreferrer"
        class="inline-block ml-2"
      >
        <ChromeSvg />
      </a>
    </p>
  </div>
{/if}
